import { styled } from '@mui/system';

import Typography from '@ui/Typography';

const MediaTitle = styled(Typography)(({ theme, variant }) => ({
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
    display: variant === 'p' ? 'none' : '-webkit-box',
    [theme.breakpoints.up('md')]: {
        display: '-webkit-box',
    },
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
}));

export default MediaTitle;
