/* eslint-disable max-lines */
import React, { Fragment, useMemo } from 'react';

import { styled } from '@mui/system';
import { getThumbnail } from '@utils/asset';
import { useIllustration } from '@utils/illustrations';
import { isContentLocked } from '@utils/media';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import Box from '@ui/Box';
import Link from '@ui/link/Link';
import MaxWidthBox from '@ui/MaxWidthBox';
import Typography from '@ui/Typography';

import AdminEditLink from '@components/admin/AdminEditLink';
import MediaCardCover from '@components/Media/mediaCardElements/MediaCardCover';

import MediaItemActions from './MediaItemActions';

const MediaDetailHeaderContainer = styled(Box)(({ theme, src }) => ({
    marginBottom: theme.spacing(6),
    marginTop: -88,
    marginLeft: `-${theme.spacing(2)}`,
    marginRight: `-${theme.spacing(2)}`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        marginLeft: `-${theme.spacing(3)}`,
        marginRight: `-${theme.spacing(3)}`,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    paddingTop: 88,
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.default}DE, ${theme.palette.background.default}), url('${src}')`,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
}));

const MediaDetailHeaderContent = styled(MaxWidthBox)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
}));

const MediaTitle = styled(Typography)(({ theme }) => ({
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    display: '-webkit-box',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));

const MediaDetailHeaderCoverContainer = styled('div')(({ theme }) => ({
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(2),
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(4),
        width: 200,
        height: 200,
    },
    [theme.breakpoints.up('md')]: {
        width: 250,
        height: 250,
        marginBottom: 0,
    },
    [theme.breakpoints.up('lg')]: {
        width: 300,
        height: 300,
    },
}));

const MediaDetailHeader = ({ mediaData, hideActions, disableHostLink, extra }) => {
    const { title, hosts, image, type, parentMedia } = mediaData;
    const router = useRouter();
    const { podcastImage } = useIllustration();
    const isLockedMemo = useMemo(() => isContentLocked(mediaData), [mediaData]);

    const heroImage = getThumbnail(image || parentMedia?.image) || podcastImage?.src;
    const coverImage = getThumbnail(image || parentMedia?.image) || podcastImage?.src;

    const hostsLinks = () => {
        if (disableHostLink) {
            return hosts?.map(({ title }) => title).join(', ');
        }

        return hosts?.map((host, index) => (
            <Fragment key={host?.slug}>
                <Link color="inherit" underline="hover" href={`/${host?.slug}`}>
                    {host?.title}
                </Link>
                {index < hosts.length - 1 && <>, </>}
            </Fragment>
        ));
    };

    return (
        <MediaDetailHeaderContainer src={heroImage}>
            <MediaDetailHeaderContent>
                <MediaDetailHeaderCoverContainer>
                    <MediaCardCover
                        // mediaType={type}
                        isLocked={isLockedMemo}
                        type={type}
                        url={coverImage}
                        alt={title}
                        completeness={mediaData?.position?.completeness}
                        isExclusive={
                            mediaData?.properties?.isExclusive || mediaData?.parentMedia?.properties?.isExclusive
                        }
                    />
                </MediaDetailHeaderCoverContainer>
                <Box sx={{ flexGrow: 1 }}>
                    {parentMedia && (
                        <Typography variant="h3">
                            <Link color="inherit" underline="hover" href={`/${parentMedia?.slug}`}>
                                {parentMedia.title}
                            </Link>
                        </Typography>
                    )}
                    {extra}
                    <MediaTitle variant="h1">
                        {title}
                        <AdminEditLink mediaData={mediaData} />
                    </MediaTitle>
                    <Typography variant="h2" mb={1}>
                        {hostsLinks()}
                    </Typography>
                    {!hideActions && (
                        <MediaItemActions mediaStartTime={router.query.t} mediaData={mediaData} sx={{ mt: 2 }} />
                    )}
                </Box>
            </MediaDetailHeaderContent>
        </MediaDetailHeaderContainer>
    );
};

MediaDetailHeader.propTypes = {
    hideActions: PropTypes.bool,
    mediaData: PropTypes.object,
    disableHostLink: PropTypes.bool,
    extra: PropTypes.element,
};

export default MediaDetailHeader;
