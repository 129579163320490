import React from 'react';

import { useIllustration } from '@utils/illustrations';

import MaxWidthBox from '@ui/MaxWidthBox';

import useCms from '@components/Cms/useCms';
import NewestMedias from '@components/Recommendations/NewestMedias';

import ErrorBanner from './ErrorBanner';

const Error = ({ statusCode, title, description }) => {
    const cms = useCms();

    const is404Error = statusCode === 404;
    const { brokenLink } = useIllustration();

    const errorTitle = title || `${is404Error ? cms[382] : cms[385]}`;
    const errorDescription = description || is404Error ? cms[383] : cms[386];

    return (
        <MaxWidthBox>
            <ErrorBanner src={brokenLink.src} title={errorTitle} description={errorDescription} />
            <NewestMedias />
        </MaxWidthBox>
    );
};

export default Error;
