import React from 'react';

import MediaPage, { serverSideProps } from '@components/pages/Media/MediaPage';
import pageConfig from '@components/pages/Media/mediaPageConfig';

const Media = ({ serverMediaData }) => <MediaPage serverMediaData={serverMediaData} />;

Media.pageConfig = pageConfig;

export const getServerSideProps = serverSideProps;

export default Media;
