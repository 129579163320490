import React from 'react';

import { CardContent as MuiCardContent } from '@mui/material';

const CardContent = props => {
    const { children, ...rest } = props;
    return <MuiCardContent {...rest}>{children}</MuiCardContent>;
};

export default CardContent;
