import React from 'react';

import useCms from '@components/Cms/useCms';

import ConnectedMediaGridList from './ConnectedMediaGridList';

const HostsGridList = ({ isNew, ...rest }) => {
    const cms = useCms();

    return <ConnectedMediaGridList type="host" size="small" title={isNew ? cms[580] : cms[582]} {...rest} />;
};

export default HostsGridList;
