import React from 'react';

import { styled } from '@mui/system';

import Typography from '@ui/Typography';

const DescriptionContainer = styled(Typography)(({ theme }) => ({
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
    display: '-webkit-box',
    [theme.breakpoints.up('sm')]: {
        WebkitLineClamp: 2,
    },
    [theme.breakpoints.up('md')]: {
        WebkitLineClamp: 3,
    },
}));

const Description = ({ description }) => {
    return <DescriptionContainer variant="body2">{description}</DescriptionContainer>;
};

export default Description;
