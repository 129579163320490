const baseUrl = process.env.NEXT_PUBLIC_SITE_URL;

const mediaGetSchemaJson = (pageProps, cms) => {
    const { title, description, publishedAt, season, type, bannerImage, hosts, slug, duration, parentMedia } =
        pageProps?.serverMediaData || {};

    const url = `${baseUrl}/${slug}`;
    const name = title ? `${title} | ${cms[500]}` : cms[500];

    const baseProps = {
        image: bannerImage,
        url,
        name,
        description,
    };

    const author = {
        '@type': 'Person',
        name: hosts?.[0]?.title,
        url: `${baseUrl}/${hosts?.[0]?.slug}`,
    };

    const datePublished = new Date(publishedAt * 1000).toLocaleDateString();

    const typeMap = {
        podcast: {
            '@context': 'https://schema.org/',
            '@type': 'PodcastSeries',
            author,
            datePublished,
        },
        episode: {
            '@context': 'https://schema.org/',
            '@type': 'PodcastEpisode',
            datePublished,
            duration: Math.round(duration?.seconds),
            author,
            partOfSeason: {
                seasonNumber: season,
            },
            partOfSeries: {
                '@type': 'PodcastSeries',
                name: parentMedia?.title,
                url: `${baseUrl}/${parentMedia?.slug}`,
            },
        },
        host: {
            '@type': 'Person',
        },
        radio: {
            '@type': 'RadioBroadcastService',
        },
        category: {
            '@type': 'ItemList',
        },
    };

    return {
        ...baseProps,
        ...(typeMap[type] || {}),
    };
};

export default mediaGetSchemaJson;
