const baseUrl = process.env.NEXT_PUBLIC_SITE_URL;

const imageSizeMap = {
    radio: 'square',
    host: 'square',
    podcast: 'rectangle',
    episode: 'square',
    category: 'square',
};

const imageDimensions = {
    square: {
        imageWidth: '1200',
        imageHeight: '1200',
    },
    rectangle: {
        imageWidth: '1200',
        imageHeight: '628',
    },
};

const mediaGetMeta = (pageProps, cms) => {
    const { title, description, publishedAt, type, image, hosts, slug, duration, parentMedia } =
        pageProps?.serverMediaData || {};
    const url = `${baseUrl}/${slug}`;
    const metaTitle = title ? `${title} | ${cms[500]}` : cms[500];

    const baseInfo = [
        {
            propName: 'property',
            propValue: 'music:release_date',
            content: new Date(publishedAt * 1000).toLocaleDateString(),
        },
        ...(hosts || []).map(host => {
            return {
                propName: 'property',
                propValue: 'music:musician',
                content: `${baseUrl}/${host.slug}`,
            };
        }),
    ];

    const typeMap = {
        podcast: {
            ogType: 'music.album',
            misc: baseInfo,
        },
        episode: {
            ogType: 'music.song',
            misc: [
                ...baseInfo,
                {
                    propName: 'property',
                    propValue: 'music:duration',
                    content: Math.round(duration?.seconds),
                },
                {
                    propName: 'property',
                    propValue: 'music:album',
                    content: `${baseUrl}/${parentMedia?.slug}`,
                },
            ],
        },
        radio: {
            ogType: 'music.radio_station',
            misc: baseInfo,
        },
        host: {
            ogType: 'profile',
            misc: [
                {
                    propName: 'property',
                    propValue: 'profile:first_name',
                    content: title?.split(' ')?.[0],
                },
                {
                    propName: 'property',
                    propValue: 'profile:last_name',
                    content: title?.split(' ')?.[1],
                },
            ],
        },
    };

    const extraTags = typeMap[type] || {};

    return {
        url,
        description,
        title: metaTitle,
        image,
        ...(imageDimensions[imageSizeMap[type]] || {}),
        ...extraTags,
    };
};

export default mediaGetMeta;
