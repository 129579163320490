import { useQuery } from '@utils/query';

const useMediaQuery = ({ mediaSlug }) => {
    return useQuery({
        endpoint: `/media/${mediaSlug}`,
        disableRedirect: true,
        muteNotifications: true,
    });
};

export default useMediaQuery;
