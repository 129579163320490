import React from 'react';

import { Skeleton } from '@mui/material';
import { styled } from '@mui/system';

import Box from '@ui/Box';

const Wrapper = styled('div')(({ theme }) => ({
    display: 'block',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const Container = styled('div')({
    display: 'flex',
});

const ImgWrapper = styled('div')(({ theme }) => ({
    marginRight: 0,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(2),
        marginBottom: 0,
    },
}));

const MediaListItemLoading = ({ draggable }) => {
    return (
        <Container>
            {draggable && (
                <Box py={4} pr={2}>
                    <Skeleton variant="rectangular" width={24} height={24} />
                </Box>
            )}
            <Wrapper>
                <ImgWrapper>
                    <Skeleton variant="rectangle" width={100} height={100} sx={{ borderRadius: 1 }} />
                </ImgWrapper>
                <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-between">
                    <Skeleton variant="text" />
                    <Skeleton variant="rectangle" width="100%" height="46px" sx={{ my: 1 }} />
                    <Box display="flex" alignItems="center">
                        <Box display="flex" alignItems="center">
                            <Skeleton variant="circular" width={46} height={46} sx={{ mr: 3 }} />
                        </Box>
                        <Skeleton variant="rectangle" width="100%" />
                        <Box display="flex" alignItems="center">
                            <Skeleton variant="circular" width={24} height={24} sx={{ ml: 3 }} />
                        </Box>
                    </Box>
                </Box>
            </Wrapper>
        </Container>
    );
};

export default MediaListItemLoading;
