import React, { useMemo, useState } from 'react';

import { styled } from '@mui/system';
import { mediaTypes } from '@utils/media';
import { useListQuery } from '@utils/query';

import Box from '@ui/Box';
import Grid from '@ui/Grid';
import Button from '@ui/inputs/Button';
import Select from '@ui/inputs/Select/Select';
import Typography from '@ui/Typography';

import Cms from '@components/Cms/Cms';
import useCms from '@components/Cms/useCms';
import MediaListItem from '@components/Media/MediaListItem';

import MediaListLoading from './MediaListLoading';

const EpisodeTitleContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});
const allSeason = 'all';

const MediaList = ({ mediaData, md = 12 }) => {
    const { seasons, currentSeason, cursor, type, slug, parentMedia } = mediaData;
    const isEpisode = type === mediaTypes.episode;
    const parentSlugOrId = isEpisode ? parentMedia?.slug : slug;
    const cms = useCms();
    const [selectedSeason, setSelectedSeason] = useState(currentSeason || allSeason);

    const seasonOptions = seasons
        ?.sort((a, b) => b?.number - a?.number)
        ?.map(({ number }) => ({
            value: number,
            name: <Cms cmsId={808} args={[number]} />,
        }));

    const seasonOptionsWithAll = seasonOptions?.length
        ? [
              ...seasonOptions,
              {
                  value: allSeason,
                  name: cms[811],
              },
          ]
        : [];

    const handleChange = e => {
        setSelectedSeason(e.target.value);
    };

    const [mediaList, mediaListLoading, totalCount, loadMore, _reload, _error, pageInfo] = useListQuery({
        endpoint: `/media`,
        filters: {
            parentSlugOrId,
            season: selectedSeason !== allSeason ? selectedSeason : null,
            type: mediaTypes.episode,
            ...(isEpisode ? { before: cursor } : { after: cursor }),
        },
        muteNotifications: true,
    });

    const orderedMediaList = useMemo(() => {
        return (mediaList || []).sort((a, b) => (isEpisode ? a.order - b.order : b.order - a.order));
    }, [mediaList, isEpisode]);

    const mediaListLength = orderedMediaList.length;

    const hasLoadMore = totalCount > mediaListLength && pageInfo?.hasNextPage;

    if (!mediaListLength && !mediaListLoading) {
        return null;
    }

    return (
        <Grid item xs={12} sm={12} md={md}>
            <EpisodeTitleContainer>
                <Typography variant="h4" component="h3" color="text.secondary" gutterBottom sx={{ flexGrow: 1 }}>
                    {type === mediaTypes.episode ? cms[812] : cms[810]}
                </Typography>
                {seasons?.length > 1 && (
                    <Select
                        value={selectedSeason}
                        onChange={handleChange}
                        displayEmpty
                        options={seasonOptionsWithAll}
                        inputProps={{ 'aria-label': cms[809] }}
                        sx={{ minWidth: 125 }}
                    ></Select>
                )}
            </EpisodeTitleContainer>
            {mediaListLoading && <MediaListLoading />}
            {orderedMediaList.map(media => (
                <MediaListItem mediaData={media} key={media?.id} />
            ))}
            {hasLoadMore && (
                <Box textAlign="center" mt={3}>
                    <Button variant="text" onClick={() => loadMore()}>
                        {cms[577]}
                    </Button>
                </Box>
            )}
        </Grid>
    );
};

export default MediaList;
