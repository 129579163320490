import React from 'react';

import { styled } from '@mui/system';
import { mediaTypes } from '@utils/media';

import Typography from '@ui/Typography';

import useCms from '@components/Cms/useCms';

import PlayButton from './PlayButton';

const Container = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    transition: 'background-color 0.2s',
    marginBottom: theme.spacing(3),
}));

const Title = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(2),
}));

const MediaIntro = ({ mediaData }) => {
    const { type, streamingUrls } = mediaData;
    const cms = useCms();
    return (
        <>
            {type === mediaTypes.podcast && streamingUrls?.length && (
                <Container>
                    <PlayButton mediaData={mediaData} small />
                    <Title variant="body1">
                        {cms[2010]} ({mediaData?.duration?.formatted})
                    </Title>
                </Container>
            )}
        </>
    );
};

export default MediaIntro;
