import React from 'react';

import { useRouter } from 'next/router';

import { fetchMediaSlugOrId } from '@components/api/serverApi';
import Error from '@components/Error';
import useMediaQuery from '@components/Media/hooks/useMediaQuery';
import MediaDetail from '@components/Media/MediaDetail';

const MediaPage = ({ serverMediaData }) => {
    const { query } = useRouter();
    const { mediaSlug } = query;
    const [mediaData] = useMediaQuery({ mediaSlug });

    if (serverMediaData?.error) {
        return <Error statusCode={404} />;
    }

    return <MediaDetail mediaData={mediaData || serverMediaData} />;
};

export const serverSideProps = async context => {
    const {
        params: { mediaSlug },
    } = context;

    const serverMediaData = await fetchMediaSlugOrId(mediaSlug);

    if (!serverMediaData?.error && serverMediaData?.slug !== mediaSlug) {
        return {
            redirect: {
                destination: `/${serverMediaData?.slug}`,
                statusCode: 302,
            },
        };
    }

    return {
        props: {
            serverMediaData,
        },
    };
};

export default MediaPage;
