import React from 'react';

import { Box } from '@mui/system';
import PropTypes from 'prop-types';

import Chip from '@ui/Chip';
import Link from '@ui/link/Link';
import Typography from '@ui/Typography';

import StrosslePlaceholder from '@components/AdBanner/StrosslePlaceholder';
import useCms from '@components/Cms/useCms';
import MediaIntro from '@components/Media/MediaIntro';
import useRadiantPlayerContext from '@components/RadiantPlayer/useRadiantPlayer';

const MediaMeta = ({ mediaData }) => {
    const { categories: mediaCategories, description: mediaDescription, chapters, parentMedia } = mediaData;
    const cms = useCms();

    const { playTrack, currentTrack, seekTo } = useRadiantPlayerContext();

    const handlePlayTrack = seconds => {
        if (currentTrack && currentTrack.id === mediaData?.id) {
            seekTo(seconds);
        } else {
            playTrack(mediaData, seconds);
        }
    };

    const categories =
        mediaCategories?.length > 0
            ? mediaCategories
            : parentMedia?.categories?.length > 0
            ? parentMedia?.categories
            : null;

    const description = mediaDescription || parentMedia?.description || '';

    if (!categories?.length > 0 && !description?.length > 0 && !chapters?.chapters?.length > 0) {
        return null;
    }

    return (
        <Box>
            <MediaIntro mediaData={mediaData} />
            {categories?.length > 0 && (
                <>
                    <Typography variant="h4" component="h3" color="text.secondary" gutterBottom>
                        {cms[21]}
                    </Typography>
                    <Box sx={{ mt: 2, mb: 3 }}>
                        {categories?.map(category => (
                            <Link key={category.slug} color="inherit" underline="none" href={`/${category.slug}`}>
                                <Chip clickable label={category.name} size="small" sx={{ mr: 1, mb: 1 }} />
                            </Link>
                        ))}
                    </Box>
                </>
            )}
            <StrosslePlaceholder />
            {description?.length > 0 && (
                <Box>
                    <Typography variant="h4" component="h3" color="text.secondary" gutterBottom>
                        {cms[20]}
                    </Typography>
                    <Typography variant="body1">{description}</Typography>
                </Box>
            )}
            {chapters?.chapters?.length > 0 && (
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h4" component="h3" color="text.secondary" gutterBottom>
                        {cms[2000]}
                    </Typography>
                    {chapters.chapters.map((chapter, i) => (
                        <div key={i}>
                            <Typography
                                variant="body1"
                                sx={{
                                    display: 'inline',
                                    cursor: 'pointer',
                                    '&:hover': { textDecoration: 'underline' },
                                }}
                                onClick={() => handlePlayTrack(chapter.seconds)}
                            >
                                {chapter.timestamp} - {chapter.title}
                            </Typography>
                        </div>
                    ))}
                </Box>
            )}
        </Box>
    );
};

MediaMeta.propTypes = {
    extra: PropTypes.element,
    mediaData: PropTypes.object,
};

export default MediaMeta;
