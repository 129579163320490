import { styled } from '@mui/system';

import Box from '@ui/Box';

const MediaItemContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.background.paper}`,
    borderRadius: theme.shape.borderRadius,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
}));

export default MediaItemContainer;
