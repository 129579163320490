import React from 'react';

import MediaListItemLoading from './MediaListItemLoading';

const defaultCount = 6;

const MediaListLoading = ({ draggable, count }) => {
    return (
        <div>
            {Array.from({ length: count || defaultCount }).map((_, index) => {
                return <MediaListItemLoading key={index} draggable={draggable} />;
            })}
        </div>
    );
};

export default MediaListLoading;
