import React from 'react';

import useCms from '@components/Cms/useCms';

import ConnectedMediaGridList from './ConnectedMediaGridList';

const RelatedGridList = ({ categoryIds, ...rest }) => {
    const cms = useCms();

    return <ConnectedMediaGridList categoryId={categoryIds} type="podcast" title={cms[906]} {...rest} />;
};

export default RelatedGridList;
