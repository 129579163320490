import React from 'react';

import { getLinks } from 'cache';

import PodcastsGridList from '@components/Media/gridLists/PodcastsGridList';
import RadiosGridList from '@components/Media/gridLists/RadiosGridList';

const NewestMedias = () => {
    return (
        <>
            <PodcastsGridList isNew linkToAll={getLinks('newest')} />
            <RadiosGridList linkToAll={getLinks('radios')} />
        </>
    );
};

export default NewestMedias;
