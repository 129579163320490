import React from 'react';

import { getLinks } from '@cache/index';
import { mediaTypes } from '@utils/media';
import { mediaColumnNumber } from '@utils/media';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';

import Box from '@ui/Box';
import Grid from '@ui/Grid';
import MaxWidthBox from '@ui/MaxWidthBox';

import MediaDetailHeader from '@components/Media/MediaDetailHeader';
import MediaMeta from '@components/Media/MediaMeta';

import EpisodeGridList from './gridLists/EpisodeGridList';
import HostsGridList from './gridLists/HostsGridList';
import PodcastsGridList from './gridLists/PodcastsGridList';
import RadiosGridList from './gridLists/RadiosGridList';
import RelatedGridList from './gridLists/RelatedGridList';
import MediaList from './MediaList';

const MediaDetail = ({ mediaData }) => {
    const { id, type, categories: mediaCategories, parentMedia } = mediaData;
    const router = useRouter();

    const categories =
        parentMedia?.categories?.length > 0 ? [...parentMedia?.categories, ...mediaCategories] : mediaCategories;
    const categoryIds = Array.from(new Set(categories.map(({ id }) => id)));

    const mediaListComponent = {
        [mediaTypes.podcast]: (
            <Grid mb={6} container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={6}>
                <MediaList mediaData={mediaData} md={8} />
                <Grid item xs={12} sm={12} md={4}>
                    <MediaMeta mediaData={mediaData} />
                </Grid>
            </Grid>
        ),
        [mediaTypes.episode]: (
            <Grid mb={6} container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={6}>
                <Grid item xs={12} md={12}>
                    <MediaMeta mediaData={mediaData} />
                </Grid>
                <MediaList mediaData={mediaData} md={12} />
            </Grid>
        ),
        [mediaTypes.host]: (
            <>
                <Box mb={6}>
                    <MediaMeta mediaData={mediaData} />
                </Box>

                <PodcastsGridList
                    titleComponent="h3"
                    hostId={id}
                    linkToAll={`${router.query.mediaSlug}/${getLinks('slugPodcastsPostfix')}`}
                />
                <EpisodeGridList titleComponent="h3" hostId={id} />
                <RadiosGridList
                    titleComponent="h3"
                    hostId={id}
                    linkToAll={`${router.query.mediaSlug}/${getLinks('slugRadiosPostfix')}`}
                />
            </>
        ),
        [mediaTypes.category]: (
            <>
                <EpisodeGridList categoryId={id} maxItems={6} isNew />
                <PodcastsGridList
                    categoryId={id}
                    linkToAll={`${router.query.mediaSlug}/${getLinks('slugPodcastsPostfix')}`}
                />
                <RadiosGridList
                    categoryId={id}
                    linkToAll={`${router.query.mediaSlug}/${getLinks('slugRadiosPostfix')}`}
                />
                <HostsGridList
                    categoryId={id}
                    linkToAll={`${router.query.mediaSlug}/${getLinks('slugHostsPostfix')}`}
                />
            </>
        ),
        [mediaTypes.radio]: (
            <Box mb={6}>
                <MediaMeta mediaData={mediaData} />
            </Box>
        ),
    };

    const relatedPodcasts = (
        <RelatedGridList titleComponent="h3" categoryId={categoryIds} forcedMaxItem={mediaColumnNumber.xl.normal} />
    );

    const relatedComponent = {
        [mediaTypes.podcast]: relatedPodcasts,
        [mediaTypes.episode]: relatedPodcasts,
        [mediaTypes.host]: null,
        [mediaTypes.category]: null,
        [mediaTypes.radio]: <RadiosGridList titleComponent="h3" linkToAll={getLinks('radios')} />,
    };

    return (
        <>
            <MediaDetailHeader mediaData={mediaData} />
            <MaxWidthBox>
                {mediaListComponent[type]}
                {relatedComponent[type] && <>{relatedComponent[type]}</>}
            </MaxWidthBox>
        </>
    );
};

MediaDetail.propTypes = {
    mediaData: PropTypes.object,
};

export default MediaDetail;
