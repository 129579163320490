import React, { useMemo } from 'react';

import { getThumbnail, imgType } from '@utils/asset';
import { useIllustration } from '@utils/illustrations';
import { isContentLocked } from '@utils/media';
import { useRouter } from 'next/router';

import Box from '@ui/Box';
import CardContent from '@ui/CardContent';
import Link from '@ui/link/Link';

import AdminEditLink from '@components/admin/AdminEditLink';
import useCms from '@components/Cms/useCms';
import Description from '@components/Media/Description';
import MediaItemActions from '@components/Media/MediaItemActions';

import MediaCardCover from './mediaCardElements/MediaCardCover';
import MediaItemContainer from './mediaListItemElements/MediaItemContainer';
import MediaListItemCoverContainer from './mediaListItemElements/MediaListItemCoverContainer';
import MediaTitle from './mediaListItemElements/MediaTitle';

const MediaListItem = ({ mediaData, isOnMyList, listId, reload }) => {
    const router = useRouter();
    const cms = useCms();
    const { podcastImage, unpublishedImage } = useIllustration();
    const isLockedMemo = useMemo(() => isContentLocked(mediaData), [mediaData]);

    const handleClick = () => {
        router.push(`/${mediaData?.slug}`);
    };

    const isPublished = mediaData?.state === 'published';
    const coverImage = !isPublished
        ? unpublishedImage?.src
        : getThumbnail(mediaData?.image, imgType.thumbnail) ||
          getThumbnail(mediaData?.parentMedia?.image, imgType.thumbnail) ||
          podcastImage?.src;

    return (
        <MediaItemContainer>
            <MediaListItemCoverContainer onClick={handleClick}>
                <MediaCardCover
                    url={coverImage}
                    //TODO size
                    isLocked={isLockedMemo}
                    size="normal"
                    alt={mediaData?.title}
                    completeness={mediaData?.position?.completeness}
                />
            </MediaListItemCoverContainer>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <CardContent sx={{ flex: '0 0 auto', padding: 0 }}>
                    <MediaTitle variant="h5" component="h4">
                        {isPublished ? (
                            <Link color="inherit" underline="hover" href={`/${mediaData?.slug}`}>
                                {mediaData?.title}
                            </Link>
                        ) : (
                            cms[800]
                        )}
                        <AdminEditLink mediaData={mediaData} />
                    </MediaTitle>
                    {isPublished && mediaData?.description && (
                        <Link color="inherit" underline="none" href={`/${mediaData?.slug}`}>
                            <Description description={mediaData?.description} />
                        </Link>
                    )}
                </CardContent>
                <Box sx={{ mt: 1 }}>
                    <MediaItemActions
                        mediaData={mediaData}
                        isOnMyList={isOnMyList}
                        listId={listId}
                        reload={reload}
                        small
                    />
                </Box>
            </Box>
        </MediaItemContainer>
    );
};

export default MediaListItem;
